import {SnackbarType} from '~/service/SnackbarService/SnackbarType';
import useSnackbarStore, {SnackbarLocation} from '~/service/SnackbarService/SnackbarStore';


export function useSnackbar() {
  const snackbarStore = useSnackbarStore();
  const openSnackbar = (type: SnackbarType, message: string, location: SnackbarLocation = 'bottom') => {
    snackbarStore.addMessage({message, type, location,});
  };
  const openWarningSnackbar = (msg: string, location: SnackbarLocation = 'bottom') => {
    openSnackbar(SnackbarType.WARNING, msg, location);
  };

  const openErrorSnackbar = (msg: string = 'Wystąpił błąd', location: SnackbarLocation = 'bottom') => {
    openSnackbar(SnackbarType.ERROR, msg, location);
  };

  const openSuccessSnackbar = (msg: string, location?: SnackbarLocation) => {
    openSnackbar(SnackbarType.SUCCESS, msg, location);
  };

  return {
    openWarningSnackbar,
    openErrorSnackbar,
    openSuccessSnackbar,
  };
}
